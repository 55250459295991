import React, { useEffect, useState } from "react"
import { graphql } from "gatsby"
import Header from "../Components/Header/Header"
import Footer from "../Components/Footer/Footer"
import GuideBanner from "../Components/Static/StaticBanner/GuideBanner"
import StaticContent from "../Components/Static/StaticBanner/StaticContent"
import ContentAccordion from "../Components/Static/StaticBanner/ContentAccordion"
import TwoColImages from "../Components/Static/StaticBanner/TwoColImages"
import FeaturedProperties from "../Components/PropertyManagement/RelatedPropertyManagement/Featured-Properties"
import BottomTeamModule from "../Components/Static/ContactTeam/ContactTeam"
import AreaguideMap from "../Components/detail-map"
import SEO from "../Components/Seo/seo"

export default props => {
  const [scroll, setScroll] = useState(false)

  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 100)
    })
  }, [])
  const GQLPage = props.data.glstrapi?.areaguide
  const GQLModules = props.data.glstrapi?.areaguide?.Modules
  return (
    <React.Fragment>
      <SEO
        title={`${GQLPage.Name} Property Guide `}
        description={`Know more about ${GQLPage.Name} here. Contact one of our property experts today to get assistance in finding the perfect property in ${GQLPage.Name}.`}
        image={GQLPage.Banner_Image?.url}
      />
      <div
        className={scroll ? "menu-fixed menu2 menu-scroll" : "menu-fixed menu2"}
      >
        <Header />
      </div>
      <GuideBanner
        Title={GQLPage.Area_Guide_Name}
        memberid={GQLPage.Select_Team_Member && GQLPage.Select_Team_Member.id}
        data={GQLPage}
      />
      <StaticContent FullContent={GQLPage.Intro_Content} />
      {GQLPage.Accordion_List !== null && (
        <ContentAccordion data={GQLPage.Accordion_List} />
      )}
      <TwoColImages
        Title={GQLPage.Area_Guide_Name}
        images={GQLPage.Two_Column_Images}
        Belowcontent={GQLPage.Content_Below_Two_Column_Images}
      />
      {GQLPage.Latitude && GQLPage.Longitude && (
        <AreaguideMap
          class="area-guide-detail"
          Title={GQLPage.Area_Guide_Name}
          Latitude={GQLPage.Latitude}
          Longitude={GQLPage.Longitude}
          type="transport"
          id="areaguides-map"
        />
      )}
      {GQLModules.map((Modules, i) => {
        return (
          <>
            {Modules.__typename === "GLSTRAPI_ComponentComponentsModules" &&
              Modules.Select_Module === "Featured_Properties_Module" && (
                <FeaturedProperties area={GQLPage.Name} />
              )}
            {Modules.__typename === "GLSTRAPI_ComponentComponentsModules" &&
              Modules.Select_Module === "Team_Bottom_Module" && (
                <BottomTeamModule />
              )}
          </>
        )
      })}
      <Footer
        popularSearch="Popular_Search_Static_Common"
        alias="area-guides"
        baseurl="area-guides"
        catalias={`${GQLPage.Select_Areaguide_Location.toLowerCase()}-area-guides`}
        catlabel={`${GQLPage.Select_Areaguide_Location} Area Guides`}
        menulabel={GQLPage.Name}
      />
    </React.Fragment>
  )
}

export const pageQuery = graphql`
  query AreaQuery($id: ID!) {
    glstrapi {
      areaguide(id: $id) {
        Name
        id
        Meta_Title
        Meta_Description
        Area_Guide_Title
        Area_Guide_Name
        Select_Areaguide_Location
        Latitude
        Longitude
        imagetransforms
        Banner_Image {
          url
          alternativeText
        }
        Select_Team_Member {
          id
        }
        Intro_Content
        Two_Column_Images {
          Column_Image {
            url
            alternativeText
          }
        }
        Youtube_Video_URL
        Accordion_List {
          id
          Accordion_Content
          Accordion_Title
        }
        Content_Below_Two_Column_Images
        Modules {
          ... on GLSTRAPI_ComponentComponentsModules {
            id
            __typename
            Select_Module
          }
        }
      }
    }
  }
`
