import { Link } from "gatsby"
import React, { useEffect, useState, useRef } from "react"
import ScrollAnimation from "react-animate-on-scroll"
import {
  Button,
  Navbar,
  Nav,
  Form,
  Container,
  FormControl,
  Row,
  Col,
  dark,
  Card,
} from "react-bootstrap"
import "./StaticBanner.scss"
import AccordionContext from "react-bootstrap/AccordionContext"
import { useAccordionToggle } from "react-bootstrap/AccordionToggle"
import { useContext } from "react"
import { useLocation } from "@reach/router"
import ReactMarkdown from "react-markdown/with-html"
import LoadExternalScript from "../../../Components/utils/load-external-script"



const StaticBanner = props => {
  const [isPlay, setPlay] = useState(false);
  const location = useLocation()
  const currentUrl = location.href;
  const [showDeclaration, setShowDeclaration] = useState(false);
  const domiangroupId = "8f3d6cf7-99f7-4d35-8b66-a5b0621a2282";

  useEffect(() => {
    if(currentUrl.includes("privacy-and-cookie-policy")){
      setShowDeclaration(true)
    }
   
  }, [])


  function ContextAwareToggle({ children, eventKey, callback }) {
    const currentEventKey = useContext(AccordionContext)

    const decoratedOnClick = useAccordionToggle(
      eventKey,
      () => callback && callback(eventKey)
    )

    const isCurrentEventKey = currentEventKey === eventKey

    return (
      <button
        type="button"
        className={
          isCurrentEventKey ? "active_card inactive_card" : "inactive_card"
        }
        onClick={decoratedOnClick}
      >
        {children}
        <i
          className={
            isCurrentEventKey ? "icon-minus-accordion " : "icon-plus-accordion"
          }
        ></i>
      </button>
    )
  }

  const windowUrl =
    typeof window !== "undefined" ? window.location.href?.split("/")[4] : ""

  return (
    <React.Fragment>
      {/* {props.IntroContent || props.FullContent && */}
      <section className={`static-article-data static-content ${windowUrl}`}>
        <Container>
          <Row>
            <Col lg={12}>
              <ScrollAnimation
                animateOnce={true}
                duration={0.5}
                animateIn="fadeInUp"
              >
                <div className="">
                  <div className="static-article-content">
                    <div className="article-content-bold">
                      <ReactMarkdown
                        source={props.IntroContent}
                        escapeHtml={false}
                      />
                    </div>
                    <div className="article-text" id="cookie" >
                      <ReactMarkdown
                        source={props.FullContent}
                        escapeHtml={false}
                      />
                      {
                        showDeclaration && 
                        <LoadExternalScript
                        src={`https://consent.cookiebot.com/${domiangroupId}/cd.js`} 
                        appendScriptTo="id"
                        defer="true"
                        async="true"
                        appendScriptToSelector="cookie"
                      />
                      }
                    </div>
                  </div>
                </div>
              </ScrollAnimation>
            </Col>
          </Row>
        </Container>
      </section>
      {/* } */}
    </React.Fragment>
  )
}
export default StaticBanner
