import React, { useState } from "react"
import { useQuery } from "@apollo/client"
import gql from "graphql-tag"
import { Link } from "gatsby"
import userImgDesk from "../images/Static/stamp-img.png"
import userImgTab from "../images/Static/stamp-img.png"
import userImgMob from "../images/Static/stamp-img.png"
import Modal from "react-bootstrap/Modal"
import MemberContact from "../Components/forms/member-contact"

const GETMEMBER = gql`
  query GetMemberQuery($ID: String!) {
    ourPeople(where: { id: $ID }) {
      id
      Name
      Staff_Image {
        url
      }
      Email
      Phone_Number
    }
  }
`

const GetMember = props => {
  const [show, setShow] = useState(false)
  const handleClose = () => setShow(false)
  const handleShow = event => {
    setShow(true)
  }
  const { loading, error, data } = useQuery(GETMEMBER, {
    variables: { ID: props.memberid },
  })
  if (loading) return <p>Loading ...</p>

  const categoryName = props?.category?.Name

  let emailId
  let mobileNumber
  if (categoryName) {
    emailId =
      categoryName === "London"
        ? "community@locationlocation.com"
        : "andrew@locationlocation.com"
    mobileNumber = categoryName === "London" ? "0207 923 9222" : "0184 281 8688"
  }
  return (
    <>
      {data &&
        data.ourPeople.map(member => {
          return (
            <div className="banner-user-details 32131">
              {/* <div className="banner-user-img">
                        <picture>
                            <source media="(min-width:992px)" srcSet={member.Staff_Image.url} />
                            <source media="(min-width:768px)" srcSet={member.Staff_Image.url} />
                            <img src={member.Staff_Image.url} alt="" />
                        </picture>
                    </div> */}
              <div className="banner-user-content">
                <span className="name">{member.Name}</span>
                <span className="details">
                  {member.Phone_Number && (
                    <a
                      href={`tel:${
                        categoryName ? mobileNumber : member.Phone_Number
                      }`}
                      className="btn-text-link"
                    >
                      {categoryName ? mobileNumber : member.Phone_Number}
                    </a>
                  )}
                  {member.Email && (
                    <>
                      /{" "}
                      <a
                        onClick={() => handleShow()}
                        href="javascript:void(0);"
                        className="btn-text-link"
                      >
                        Email
                      </a>
                      <Modal
                        show={show}
                        centered={true}
                        onHide={handleClose}
                        backdrop="static"
                        keyboard={false}
                        dialogClassName="modal-90w modal-form modal-form-buy-sell-rent reviewPopup"
                      >
                        <Modal.Header closeButton className="contact-close-btn">
                          <Modal.Title className="w-100">
                            Contact - {member.Name}
                          </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                          <p></p>
                          <MemberContact
                            to_email_id={categoryName ? emailId : member.Email}
                            category={categoryName}
                          />
                        </Modal.Body>
                      </Modal>
                    </>
                  )}
                </span>
              </div>
            </div>
          )
        })}
    </>
  )
}
export default GetMember
