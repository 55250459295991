import { Link } from "@StarberryUtils"
import React, { useEffect, useState, useRef } from "react"
import ScrollAnimation from "react-animate-on-scroll"
import {
  Button,
  Navbar,
  Nav,
  Form,
  Container,
  FormControl,
  Row,
  Col,
  dark,
} from "react-bootstrap"
import "./RelatedPropertyManagement.scss"
import Carousel from "react-multi-carousel"
import "react-multi-carousel/lib/styles.css"
import relatedPropImg1Desk from "../../../images/PropertyDetails/related-prop-img1.jpg"
import relatedPropImg1Tab from "../../../images/PropertyDetails/related-prop-img1.jpg"
import relatedPropImg1Mob from "../../../images/PropertyDetails/related-prop-img1.jpg"

import relatedPropImg2Desk from "../../../images/PropertyDetails/related-prop-img2.jpg"
import relatedPropImg2Tab from "../../../images/PropertyDetails/related-prop-img2.jpg"
import relatedPropImg2Mob from "../../../images/PropertyDetails/related-prop-img2.jpg"

import relatedPropImg3Desk from "../../../images/PropertyDetails/related-prop-img3.jpg"
import relatedPropImg3Tab from "../../../images/PropertyDetails/related-prop-img3.jpg"
import relatedPropImg3Mob from "../../../images/PropertyDetails/related-prop-img3.jpg"

import enquiryImgDesk1 from "../../../images/enquiry-img2.png"
import enquiryImgTab1 from "../../../images/enquiry-img2.png"
import enquiryImgMob1 from "../../../images/enquiry-img2.png"

import enquiryImgDesk2 from "../../../images/enquiry-img4.png"
import enquiryImgTab2 from "../../../images/enquiry-img4.png"
import enquiryImgMob2 from "../../../images/enquiry-img4.png"

import enquiryImgDesk3 from "../../../images/enquiry-img3.png"
import enquiryImgTab3 from "../../../images/enquiry-img3.png"
import enquiryImgMob3 from "../../../images/enquiry-img3.png"
import axios from "axios"
import ImageTransform from "../../Common/ggfx-client/module/components/image-transform"
import GetImageCount from "../../getpropertyimagecount"
import GetOffice from "../../getoffice"

const RelatedPropertyManagement = props => {
  const strapiconfig = {
    headers: {
        Authorization:
            `Bearer ${process.env.GATSBY_STRAPI_FORM_TOKEN}`,
    },
  }
  const [propItems, setPropItems] = useState(false)
  const getitems = async url => {
    try {
      const { data } = await axios.get(url, strapiconfig)
      setPropItems(data)
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    let officeId = props.officeId ?? ""
    let area = props.area ?? ""
    let type = props.type ? props.type : "sales"
    let url =
      process.env.GATSBY_STRAPI_SRC +
      `/properties?search_type=${type}&_limit=3&_sort=createdAt:DESC&publish=true` // TODO: base URL supposed to be from .env

    if (area) {
      url = url + "&display_address_contains=" + area
    }
    if (officeId) {
      url = url + "&office_crm_id=" + officeId
    }

    getitems(url)
  }, [])

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 3000, min: 1700 },
      items: 3.2,
      partialVisibilityGutter: 0,
    },
    desktop: {
      breakpoint: { max: 1699, min: 1024 },
      items: 3,
      partialVisibilityGutter: 0,
    },
    tablet: {
      breakpoint: { max: 1024, min: 768 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 767, min: 0 },
      items: 1,
    },
  }
  if (propItems && propItems.length > 0) {
    return (
      <React.Fragment>
        <section className="related-properties-management">
          <Container>
            <Row>
              <Col sm={12}>
                <ScrollAnimation animateOnce={true} duration={0.5} animateIn="fadeInUp">
                  <h2>
                    Properties that may interest you in{" "}
                    {props.area ? props.area + "," : ""} London
                  </h2>
                </ScrollAnimation>
                <Carousel
                  centerMode={false}
                  responsive={responsive}
                  showDots={true}
                  draggable={true}
                  autoPlay={false}
                  swipeable={true}
                  infinite={true}
                  removeArrowOnDeviceType={["tablet", "mobile", "desktop"]}
                >
                  {propItems &&
                    propItems.map((item, key) => {
                      let processedImages = JSON.stringify({})
                      if (item?.imagetransforms?.images_Transforms) {
                        processedImages = item.imagetransforms.images_Transforms
                      }
                      var checkptype =
                        item.search_type === "sales"
                          ? "/property-for-sale/"
                          : "/property-to-rent/"
                      return (
                        <ScrollAnimation
                          animateOnce={true}
                          animateIn="fadeInUp"
                        >
                          <div className="item">
                            <div className="related-prop-img-block">
                              <Link to={`${checkptype}${item.slug}-${item.id}`}>
                                <ImageTransform
                                  imagesources={item.images[0].url}
                                  renderer="srcSet"
                                  imagename="property.images.similarpropertytile"
                                  attr={{
                                    alt:
                                      item.display_address +
                                      " -  Location Location",
                                  }}
                                  imagetransformresult={processedImages}
                                  id={item.id}
                                  testparam={true}
                                />
                              </Link>
                              <GetImageCount id={item.id} />
                            </div>

                            <div className="related-prop-content">
                              <h2>
                                <Link
                                  to={`${checkptype}${item.slug}-${item.id}`}
                                  className="btn-text-link"
                                >
                                  {item.display_address}
                                </Link>
                              </h2>
                              <p className="amount">
                                {"£" + item.price.toLocaleString()}
                              </p>
                              <p className="description">{item.title}</p>
                              <div class="list-enquiry">
                                <GetOffice
                                  phone={
                                    item.office_details &&
                                    item.office_details.telephone
                                  }
                                />
                                <p class="enquiry-text">
                                  <span>
                                    <Link
                                      to={`${checkptype}${item.slug}-${item.id}/?id=booking`}
                                      className="border-bottom-yellow"
                                    >
                                      Book a Viewing
                                    </Link>{" "}
                                    {item.office_details.telephone &&
                                    <>
                                     or call{" "}
                                    <a
                                      className="border-bottom-yellow-slide"
                                      href={`tel:${item.office_details.telephone}`}
                                    >
                                      {item.office_details.telephone}
                                    </a></>
                                    }
                                   
                                  </span>
                                </p>
                              </div>
                            </div>
                          </div>
                        </ScrollAnimation>
                      )
                    })}
                </Carousel>
              </Col>
            </Row>
          </Container>
        </section>
      </React.Fragment>
    )
  } else {
    return <empty></empty>
  }
}

export default RelatedPropertyManagement
