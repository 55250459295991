import React from "react"
import ScrollAnimation from "react-animate-on-scroll"
import { Container, Row, Col, Card } from "react-bootstrap"
import "./StaticBanner.scss"
import Accordion from "react-bootstrap/Accordion"
import AccordionContext from "react-bootstrap/AccordionContext"
import { useAccordionToggle } from "react-bootstrap/AccordionToggle"
import { useContext } from "react"
import ReactMarkdown from "react-markdown/with-html"

const StaticBanner = props => {
  function ContextAwareToggle({ children, eventKey, callback }) {
    const currentEventKey = useContext(AccordionContext)

    const decoratedOnClick = useAccordionToggle(
      eventKey,
      () => callback && callback(eventKey)
    )

    const isCurrentEventKey = currentEventKey === eventKey

    return (
      <button
        type="button"
        className={
          isCurrentEventKey ? "active_card inactive_card" : "inactive_card"
        }
        onClick={decoratedOnClick}
      >
        {children}
        <i
          className={
            isCurrentEventKey ? "icon-minus-accordion " : "icon-plus-accordion"
          }
        ></i>
      </button>
    )
  }

  return (
    <React.Fragment>
      {props.data && props.data?.length > 0 && (
        <section className="static-article-data accordion-static-content">
          <Container>
            <Row>
              <Col lg={12}>
                <div className="">
                  <div className="accordion-block">
                    <ScrollAnimation
                      animateOnce={true}
                      duration={0.5}
                      animateIn="fadeInUp"
                    >
                      <Accordion>
                        {props.data.map(item => {
                          return (
                            <>
                              <Card>
                                <div className="accordion-header">
                                  <ContextAwareToggle
                                    eventKey={item.id}
                                    className="accordion-header"
                                  >
                                    <span>{item.Accordion_Title}</span>
                                  </ContextAwareToggle>
                                </div>

                                <Accordion.Collapse eventKey={item.id}>
                                  <div className="accordion-card accordion-card-first questions">
                                    <div className="content">
                                      <ReactMarkdown
                                        source={item.Accordion_Content}
                                        escapeHtml={false}
                                      />
                                    </div>
                                  </div>
                                </Accordion.Collapse>
                              </Card>
                            </>
                          )
                        })}
                      </Accordion>
                    </ScrollAnimation>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      )}
    </React.Fragment>
  )
}
export default StaticBanner
