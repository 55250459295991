import React from "react"
import ScrollAnimation from "react-animate-on-scroll"
import { Container, Row, Col, Card } from "react-bootstrap"
import "./StaticBanner.scss"
import Accordion from "react-bootstrap/Accordion"
import AccordionContext from "react-bootstrap/AccordionContext"
import { useAccordionToggle } from "react-bootstrap/AccordionToggle"
import { useContext } from "react"
import ReactMarkdown from "react-markdown/with-html"

const StaticBanner = props => {
  function ContextAwareToggle({ children, eventKey, callback }) {
    const currentEventKey = useContext(AccordionContext)

    const decoratedOnClick = useAccordionToggle(
      eventKey,
      () => callback && callback(eventKey)
    )

    const isCurrentEventKey = currentEventKey === eventKey

    return (
      <button
        type="button"
        className={
          isCurrentEventKey ? "active_card inactive_card" : "inactive_card"
        }
        onClick={decoratedOnClick}
      >
        {children}
        <i
          className={
            isCurrentEventKey ? "icon-minus-accordion " : "icon-plus-accordion"
          }
        ></i>
      </button>
    )
  }

  return (
    <React.Fragment>
      {props.Belowcontent && (
        <section className="static-article-data accordion-static-content">
          <Container className="additional-safety-img-box">
            <Row>
              {props.images?.map((image, index) => {
                return (
                  <>
                    {image.Column_Image?.url && (
                      <Col md={6} className="pr-xl-4">
                        <ScrollAnimation
                          animateOnce={true}
                          duration={0.5}
                          animateIn="fadeIn"
                        >
                          <div className="grid-images">
                            <picture>
                              <source
                                media="(min-width:992px)"
                                srcSet={image.Column_Image?.url}
                              />
                              <source
                                media="(min-width:768px)"
                                srcSet={image.Column_Image?.url}
                              />
                              <img
                                src={image.Column_Image?.url}
                                alt={
                                  image.Column_Image?.alternativeText
                                    ? image.Column_Image?.alternativeText
                                    : props.Title +
                                      " Gallery " +
                                      index +
                                      " - Location Location"
                                }
                              />
                            </picture>
                          </div>
                        </ScrollAnimation>
                      </Col>
                    )}
                  </>
                )
              })}
            </Row>
            {props.Belowcontent && (
              <Row>
                <Col md={12}>
                  <ScrollAnimation
                    animateOnce={true}
                    duration={0.5}
                    animateIn="fadeInUp"
                  >
                    <div className="">
                      <div className="addtional-safety-content-more">
                        <ReactMarkdown
                          source={props.Belowcontent}
                          escapeHtml={false}
                        />
                      </div>
                    </div>
                  </ScrollAnimation>
                </Col>
              </Row>
            )}
          </Container>
        </section>
      )}
    </React.Fragment>
  )
}
export default StaticBanner
